.home-container10 {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: 1000px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: var(--dl-color-primary-100);
}
.home-header1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-mobile-navigation {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 150;
  position: fixed;
  transform: translateX(100%);
  transition: transform 0.3s;
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-logo1 {
  width: 64px;
}
.home-links1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
}
.home-close-mobile-menu {
  top: var(--dl-space-space-oneandhalfunits);
  right: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  display: flex;
}
.home-desktop-navigation {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-oneandhalfunits);
  align-items: center;
  flex-direction: column;
}
.home-container11 {
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1200px;
  border-radius: 50px;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  background-color: #ffffff;
}
.home-image1 {
  width: 105px;
  height: auto;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link11 {
  font-style: normal;
  font-family: "Outfit";
  font-weight: 400;
  text-decoration: none;
}
.home-link21 {
  font-style: normal;
  font-family: "Outfit";
  font-weight: 400;
  text-decoration: none;
}
.home-link31 {
  font-style: normal;
  font-family: "Outfit";
  font-weight: 400;
  text-decoration: none;
}
.home-link32 {
  font-style: normal;
  font-family: "Outfit";
  font-weight: 400;
  text-decoration: none;
}
.home-link33 {
  font-style: normal;
  font-family: "Outfit";
  font-weight: 400;
  text-decoration: none;
}
.home-link10 {
  display: contents;
}
.home-get-started1 {
  transition: 0.3s;
  margin-bottom: 0px;
  text-decoration: none;
}
.home-get-started1:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-template-blue-bg);
}
.home-text13 {
  font-size: 18px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 600;
}
.home-burger-menu {
  display: none;
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-logo2 {
  height: var(--dl-size-size-xsmall);
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-links3 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-link12 {
  font-family: "Outfit";
  text-decoration: none;
}
.home-link22 {
  font-family: "Outfit";
  text-decoration: none;
}
.home-link34 {
  font-family: "Outfit";
  text-decoration: none;
}
.home-link4 {
  font-family: "Outfit";
  text-decoration: none;
}
.home-link5 {
  font-family: "Outfit";
  text-decoration: none;
}
.home-container13 {
  display: contents;
}
.home-content1 {
  display: flex;
  margin-top: 140px;
  align-items: center;
  flex-direction: column;
}
.home-text14 {
  color: rgb(255, 255, 255);
  font-size: 72px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 80px;
}
.home-text15 {
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-style: normal;
  margin-top: 16px;
  font-family: "Outfit";
  font-weight: 400;
  line-height: 30px;
}
.home-get-started2 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: 40px;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  background-color: #000000;
}
.home-get-started2:hover {
  color: black;
  background-color: #ffffff;
}
.home-text16 {
  font-size: 18px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 600;
}
.home-dashboard-preview {
  top: 650px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-primary-300);
  border-width: 30px;
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-primary-300);
}
.home-outline {
  height: auto;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-primary-100);
  border-width: 20px;
  border-radius: 70px;
  flex-direction: column;
  background-color: var(--dl-color-primary-100);
}
.home-image2 {
  width: 853px;
  height: 588px;
  z-index: 10;
  user-select: none;
  border-radius: 50px;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 1;
  align-items: center;
  padding-top: 400px;
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  background-color: #EFF0F2;
}
.home-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text17 {
  font-size: 45px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 72px;
}
.home-text18 {
  font-size: 20px;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-family: "Outfit";
  line-height: 30px;
}
.home-cards {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: 80px;
  align-items: center;
  flex-direction: column;
}
.home-container14 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-icon16 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text19 {
  font-size: 28px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text20 {
  font-size: 20px;
  font-family: "Outfit";
  line-height: 24px;
}
.home-publish {
  height: initial;
}
.home-icon18 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text21 {
  font-size: 28px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text22 {
  font-size: 20px;
  font-family: "Outfit";
  line-height: 24px;
}
.home-container15 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: row;
}
.home-icon20 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text23 {
  font-size: 28px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text24 {
  line-height: 24px;
}
.home-icon22 {
  width: 30px;
  height: 30px;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text25 {
  font-size: 28px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  line-height: 33px;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text26 {
  font-size: 20px;
  font-family: "Outfit";
  line-height: 24px;
}
.home-how-it-works {
  width: 100%;
  display: flex;
  padding: 0px;
  align-items: center;
  flex-direction: column;
  background-color: #EFF0F2;
}
.home-centered-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.home-category1 {
  color: white;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  margin-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fourunits);
  border-radius: 30px;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  background-color: #FFFFFF;
}
.home-headng {
  width: 1116px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-text27 {
  color: var(--dl-color-gray-black);
  font-size: 45px;
  align-self: center;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text28 {
  color: var(--dl-color-gray-black);
  font-size: 20px;
  align-self: center;
  text-align: center;
  font-family: "Outfit";
  line-height: 25px;
}
.home-banners {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-banner-advanced-analytics1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #FEFEFE;
}
.home-centered-container2 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container1 {
  flex: 1;
  display: flex;
}
.home-cards-image1 {
  width: 545px;
  height: 451px;
  align-self: center;
  object-fit: contain;
}
.home-right1 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text32 {
  font-size: 45px;
  text-align: right;
  font-family: "Outfit";
}
.home-text34 {
  color: var(--dl-color-theme-primary1);
}
.home-category2 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text35 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: right;
  font-family: "Outfit";
  line-height: 34px;
}
.home-banner1 {
  width: 100%;
  height: 645px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-container16 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text36 {
  font-size: 45px;
  text-align: left;
  font-family: "Outfit";
}
.home-text38 {
  color: var(--dl-color-theme-primary1);
}
.home-text40 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: "Outfit";
  line-height: 34px;
}
.home-image-container2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image2 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-banner-advanced-analytics2 {
  width: 100%;
  height: 645px;
  display: flex;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #FEFEFE;
}
.home-centered-container3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container3 {
  flex: 1;
  display: flex;
}
.home-cards-image3 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right2 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text41 {
  font-size: 45px;
  text-align: right;
  font-family: "Outfit";
}
.home-text43 {
  color: var(--dl-color-theme-primary1);
}
.home-category3 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text44 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: right;
  font-family: "Outfit";
  line-height: 34px;
}
.home-banner2 {
  width: 100%;
  height: 645px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-container17 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text45 {
  font-size: 45px;
  text-align: left;
  font-family: "Outfit";
}
.home-text47 {
  color: var(--dl-color-theme-primary1);
}
.home-text48 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: "Outfit";
  line-height: 34px;
}
.home-image-container4 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image4 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-banner-advanced-analytics3 {
  width: 100%;
  height: 645px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #FEFEFE;
}
.home-centered-container4 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
}
.home-image-container5 {
  flex: 1;
  display: flex;
}
.home-cards-image5 {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.home-right3 {
  flex: 1;
  display: flex;
  margin-left: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text49 {
  font-size: 45px;
  text-align: right;
  font-family: "Outfit";
}
.home-text51 {
  color: var(--dl-color-theme-primary1);
}
.home-category4 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text52 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: right;
  font-family: "Outfit";
  line-height: 34px;
}
.home-banner3 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: rgba(1, 165, 128, 0.14);
}
.home-container18 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-left3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text53 {
  font-size: 45px;
  font-family: "Outfit";
}
.home-text54 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-family: "Outfit";
  line-height: 34px;
}
.home-image-container6 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-cards-image6 {
  width: 568px;
  height: 591px;
  object-fit: contain;
}
.home-action-bar {
  width: 100%;
  display: flex;
  padding: 120px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-action {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  border-radius: 30px;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-primary-100);
}
.home-heading1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
}
.home-text63 {
  color: var(--dl-color-gray-white);
  font-family: "Outfit";
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text64 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-family: "Outfit";
  line-height: 24px;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-pills {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-container19 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.home-link13 {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-link14 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-images {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image3 {
  width: 100%;
  object-fit: cover;
  user-select: none;
}
.home-faq {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: center;
  background-color: #EFF0F2;
}
.home-heading2 {
  width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text65 {
  font-size: 45px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
}
.home-accordion {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text66 {
  font-family: "Outfit";
}
.home-text67 {
  font-family: "Outfit";
}
.home-header3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text68 {
  font-family: "Outfit";
}
.home-text69 {
  font-family: "Outfit";
}
.home-header4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text70 {
  font-family: "Outfit";
}
.home-text71 {
  font-family: "Outfit";
}
.home-header5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.home-text72 {
  font-family: "Outfit";
}
.home-text73 {
  font-family: "Outfit";
}
.home-container21 {
  display: contents;
}
.home-contact1 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-form {
  flex: 0 0 auto;
  width: 80%;
  height: 387px;
  display: flex;
  max-width: 700px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-space-space-twoandhalf);
  margin-right: 0px;
  padding-left: var(--dl-space-space-twoandhalf);
  border-radius: var(--dl-radius-radius-inputradius);
  margin-bottom: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-twoandhalf);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twoandhalf);
  background-color: #DBF2ED;
}
.home-text74 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  font-size: 28px;
  align-self: center;
  font-family: "Outfit";
}
.home-text75 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Outfit";
  font-weight: 500;
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.home-textinput {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  font-size: 0.87rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.25;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  text-decoration: none;
}
.home-text76 {
  color: var(--dl-color-theme-neutral-dark);
  font-size: 20px;
  margin-top: var(--dl-space-space-halfunit);
  font-family: "Outfit";
  font-weight: 500;
  margin-left: 0.25rem;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
}
.home-textarea {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-space-space-triplequarter);
  font-size: 0.87rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.25;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  text-decoration: none;
}
.home-button {
  color: white;
  cursor: pointer;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  margin-top: 40px;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 600;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-button:hover {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  background-color: var(--dl-color-theme-secondary1);
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sevenunits);
  padding-left: var(--dl-space-space-sevenunits);
  padding-right: var(--dl-space-space-sevenunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sevenunits);
  background-color: #000000;
}
.home-bottom {
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin-top: var(--dl-space-space-threeunits);
  align-items: flex-start;
  user-select: none;
  flex-direction: column;
}
.home-branding {
  width: var(--dl-size-size-large);
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);;
  margin-bottom: var(--dl-space-space-unit);
}
.home-top2 {
  width: 100%;
  height: 393px;
  display: flex;
  position: relative;
  max-width: 1200px;
  border-color: #3B3B3B;
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-right4 {
  gap: var(--dl-space-space-fiveunits);
  height: 275px;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-threeunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-category5 {
  width: 240px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.home-text77 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
  margin-bottom: 0px;
}
.home-links4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text81 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  cursor: pointer;
  font-size: 20px;
  font-family: "Outfit";
}
.home-category6 {
  width: 236px;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text86 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
}
.home-links5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link15 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-link16 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-link17 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-text90 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
}
.home-text91 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
}
.home-contact2 {
  width: 240px;
  display: flex;
  margin-top: 0px;
  align-items: stretch;
  flex-direction: column;
}
.home-text92 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
}
.home-link18 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: underline;
}
.home-link19 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-links6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text99 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-style: normal;
  font-family: "Outfit";
  font-weight: 700;
}
.home-link20 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-link23 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-link24 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-link25 {
  fill: var(--dl-color-theme-secondary1);
  color: var(--dl-color-theme-secondary1);
  font-size: 20px;
  font-family: "Outfit";
  text-decoration: none;
}
.home-text102 {
  color: var(--dl-color-theme-secondary1);
  font-family: "Poppins";
}
@media(max-width: 1600px) {
  .home-dashboard-preview {
    top: 690px;
  }
  .home-heading1 {
    align-items: flex-start;
    padding-top: var(--dl-space-space-sevenunits);
    padding-bottom: var(--dl-space-space-sevenunits);
  }
  .home-text63 {
    color: var(--dl-color-gray-white);
    text-align: left;
    font-family: Outfit;
  }
  .home-text64 {
    text-align: left;
  }
  .home-container19 {
    flex: 1;
  }
  .home-image3 {
    width: 100%;
    height: auto;
    margin-top: var(--dl-space-space-fourunits);
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-category6 {
    width: 150px;
  }
  .home-contact2 {
    width: 186px;
    margin-left: 0px;
  }
  .home-text92 {
    font-family: Outfit;
  }
  .home-link18 {
    color: var(--dl-color-theme-secondary1);
    font-family: Outfit;
  }
  .home-links6 {
    width: 160px;
    height: auto;
    align-self: flex-start;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    padding-right: 0px;
  }
  .home-text99 {
    font-family: Outfit;
  }
}
@media(max-width: 1200px) {
  .home-get-started2 {
    margin-bottom: 40px;
  }
  .home-get-leads {
    height: 303px;
  }
  .home-how-it-works {
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-radius4);
  }
  .home-centered-container1 {
    border-radius: var(--dl-radius-radius-radius4);
  }
  .home-cards-image1 {
    width: 100%;
  }
  .home-text32 {
    font-family: Outfit;
    line-height: 48px;
  }
  .home-text36 {
    line-height: 48px;
  }
  .home-text41 {
    line-height: 48px;
  }
  .home-text45 {
    line-height: 48px;
  }
  .home-text49 {
    line-height: 48px;
  }
  .home-banner3 {
    padding-right: 5px;
  }
  .home-left3 {
    width: 50%;
    height: 918px;
  }
  .home-text54 {
    align-self: center;
    text-align: left;
  }
  .home-cards-image6 {
    width: 459px;
    height: 491px;
  }
  .home-action {
    flex: 1;
  }
  .home-text63 {
    text-align: left;
  }
  .home-image3 {
    width: 100%;
    height: 100%;
  }
  .home-contact1 {
    margin-top: 2px;
  }
  .home-footer {
    padding-right: 52px;
  }
  .home-right4 {
    height: 100%;
    padding-bottom: 0px;
  }
  .home-category5 {
    width: 132px;
    height: 228px;
  }
  .home-category6 {
    width: 108px;
    align-self: flex-start;
    justify-content: flex-start;
  }
  .home-contact2 {
    width: 191px;
    padding-left: 0px;
  }
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-close-mobile-menu {
    align-items: center;
    justify-content: center;
  }
  .home-desktop-navigation {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-navbar-interactive {
    height: 60px;
  }
  .home-content1 {
    width: 100%;
    max-width: 1200px;
  }
  .home-image2 {
    width: 100%;
  }
  .home-features {
    padding-top: 260px;
  }
  .home-title {
    width: 100%;
    max-width: 1200px;
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text17 {
    font-size: 36px;
    line-height: 40px;
  }
  .home-text18 {
    font-size: 20px;
    margin-top: var(--dl-space-space-unit);
    line-height: 30px;
  }
  .home-cards {
    margin-top: var(--dl-space-space-fiveunits);
  }
  .home-text20 {
    line-height: 21px;
  }
  .home-text22 {
    line-height: 21px;
  }
  .home-analyze {
    height: 304px;
  }
  .home-text24 {
    line-height: 21px;
  }
  .home-text25 {
    font-size: 24px;
  }
  .home-text26 {
    line-height: 21px;
  }
  .home-category1 {
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-headng {
    margin-left: var(--dl-space-space-fourunits);
  }
  .home-text27 {
    text-align: center;
  }
  .home-banner-advanced-analytics1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-banner-advanced-analytics2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-banner-advanced-analytics3 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-banner3 {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-left3 {
    width: 448px;
    height: 1080px;
    margin-right: 0px;
  }
  .home-cards-image6 {
    width: 313px;
    height: 487px;
  }
  .home-action {
    flex-direction: column;
  }
  .home-heading1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text63 {
    font-size: 36px;
    align-self: center;
    text-align: center;
    line-height: 36px;
  }
  .home-text64 {
    text-align: center;
  }
  .home-pills {
    justify-content: center;
  }
  .home-text65 {
    text-align: center;
  }
  .home-accordion {
    width: 100%;
    max-width: 1200px;
  }
  .home-footer {
    padding: var(--dl-space-space-sixunits);
  }
  .home-branding {
    width: var(--dl-size-size-medium);
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .home-top2 {
    flex-direction: column;
  }
  .home-right4 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-links4 {
    gap: var(--dl-space-space-unit);
  }
  .home-links5 {
    gap: var(--dl-space-space-unit);
  }
  .home-text92 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text93 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-link18 {
    text-decoration: underline none;
  }
  .home-text95 {
    text-decoration: underline none;
  }
  .home-links6 {
    gap: var(--dl-space-space-unit);
  }
  .home-text102 {
    font-size: 12px;
    line-height: 25px;
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: 620px;
    position: relative;
    padding-bottom: 180px;
    background-color: var(--dl-color-template-blue-bg);
  }
  .home-desktop-navigation {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-navbar-interactive {
    height: 60px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image1 {
    width: 64px;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-content1 {
    height: 100%;
    margin-top: var(--dl-space-space-fourunits);
    justify-content: center;
  }
  .home-text14 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
  }
  .home-text15 {
    color: rgb(238, 233, 254);
    font-size: 16px;
    margin-top: var(--dl-space-space-unit);
    text-align: center;
    line-height: 24px;
  }
  .home-get-started2 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-dashboard-preview {
    top: 520px;
    width: 100%;
    border-width: 10px;
    border-radius: 45px;
  }
  .home-outline {
    width: auto;
    height: auto;
    border-width: 10px;
    border-radius: 35px;
  }
  .home-image2 {
    width: 100%;
    height: auto;
  }
  .home-features {
    padding-top: 350px;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text17 {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-text19 {
    font-size: 24px;
  }
  .home-text20 {
    font-size: 14px;
  }
  .home-publish {
    margin-left: 0px;
  }
  .home-text21 {
    font-size: 24px;
  }
  .home-text22 {
    font-size: 14px;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-analyze {
    margin-right: 0px;
  }
  .home-text23 {
    font-size: 24px;
  }
  .home-text24 {
    font-size: 14px;
  }
  .home-text26 {
    font-size: 14px;
  }
  .home-how-it-works {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-headng {
    padding-bottom: 0px;
  }
  .home-banner-advanced-analytics1 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container2 {
    flex-direction: column-reverse;
  }
  .home-right1 {
    margin-left: 0px;
  }
  .home-text32 {
    font-size: 28px;
    text-align: center;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text35 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container16 {
    flex-direction: column;
  }
  .home-left1 {
    margin-right: 0px;
  }
  .home-text36 {
    text-align: center;
  }
  .home-text40 {
    text-align: center;
  }
  .home-banner-advanced-analytics2 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container3 {
    flex-direction: column-reverse;
  }
  .home-right2 {
    margin-left: 0px;
  }
  .home-text41 {
    font-size: 28px;
    text-align: center;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text44 {
    text-align: center;
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner2 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container17 {
    flex-direction: column;
  }
  .home-left2 {
    margin-right: 0px;
  }
  .home-text45 {
    align-self: center;
    text-align: center;
  }
  .home-text48 {
    text-align: center;
  }
  .home-banner-advanced-analytics3 {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-centered-container4 {
    flex-direction: column-reverse;
  }
  .home-right3 {
    margin-left: 0px;
  }
  .home-text49 {
    font-size: 28px;
    text-align: left;
    line-height: 26px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text52 {
    line-height: 24px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner3 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-container18 {
    flex-direction: column;
  }
  .home-left3 {
    width: 100%;
    height: auto;
    margin-right: 0px;
  }
  .home-text53 {
    align-self: center;
    text-align: center;
  }
  .home-text54 {
    text-align: center;
  }
  .home-action-bar {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-action {
    align-items: center;
  }
  .home-heading1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text63 {
    font-size: 28px;
    align-self: center;
    text-align: left;
  }
  .home-text64 {
    align-self: center;
    text-align: center;
  }
  .home-pills {
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-images {
    justify-content: center;
  }
  .home-image3 {
    width: 100%;
  }
  .home-faq {
    padding: var(--dl-space-space-fourunits);
  }
  .home-heading2 {
    width: 100%;
  }
  .home-text65 {
    font-size: 36px;
    line-height: 36px;
  }
  .home-contact1 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-top2 {
    height: auto;
  }
  .home-category5 {
    height: auto;
    align-self: center;
  }
  .home-text77 {
    align-self: center;
  }
  .home-text81 {
    color: var(--dl-color-theme-secondary1);
    align-self: center;
    text-align: center;
    font-family: Outfit;
  }
  .home-text82 {
    font-family: Outfit;
  }
  .home-text83 {
    color: #c4c4c4;
    font-size: 14px;
    font-family: Outfit;
  }
  .home-text85 {
    font-family: Outfit;
    line-height: 1.15;
  }
  .home-category6 {
    align-self: center;
  }
  .home-text86 {
    align-self: center;
  }
  .home-link15 {
    align-self: center;
  }
  .home-link16 {
    align-self: center;
    text-align: center;
  }
  .home-link17 {
    align-self: center;
  }
  .home-text90 {
    text-align: center;
  }
  .home-text91 {
    align-self: center;
    text-align: center;
  }
  .home-contact2 {
    align-self: center;
  }
  .home-text92 {
    text-align: center;
  }
  .home-link18 {
    text-align: center;
  }
  .home-links6 {
    align-self: center;
  }
  .home-text99 {
    align-self: center;
    text-align: center;
  }
  .home-link20 {
    align-self: center;
  }
  .home-link23 {
    align-self: center;
    text-align: center;
  }
  .home-link24 {
    align-self: center;
  }
  .home-link25 {
    align-self: center;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding-bottom: 160px;
  }
  .home-logo1 {
    margin-left: 0px;
  }
  .home-icon10 {
    align-items: center;
    justify-content: center;
  }
  .home-navbar-interactive {
    height: 60px;
    padding: var(--dl-space-space-unit);
  }
  .home-image1 {
    width: 64px;
  }
  .home-link21 {
    font-style: normal;
    font-family: Outfit;
    font-weight: 400;
    text-decoration: none;
  }
  .home-get-started1 {
    display: none;
  }
  .home-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
  .home-link5 {
    font-family: Outfit;
    text-decoration: none;
  }
  .home-content1 {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-text14 {
    font-size: 36px;
    text-align: center;
  }
  .home-text15 {
    color: #EEE9FE;
    font-size: 20px;
    text-align: center;
  }
  .home-get-started2 {
    width: 100%;
  }
  .home-dashboard-preview {
    top: 500px;
    width: 90%;
    height: auto;
  }
  .home-outline {
    width: auto;
    height: auto;
  }
  .home-image2 {
    width: 100%;
    height: auto;
    border-radius: 40px;
  }
  .home-features {
    padding-top: 200px;
    border-width: 0px;
  }
  .home-title {
    margin-top: var(--dl-space-space-threeunits);
  }
  .home-text17 {
    font-size: 36px;
    text-align: center;
  }
  .home-cards {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container14 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text19 {
    text-align: center;
  }
  .home-text20 {
    font-size: 20px;
    text-align: center;
    font-family: Outfit;
    line-height: 24px;
  }
  .home-text21 {
    text-align: center;
  }
  .home-text22 {
    font-size: 20px;
    text-align: center;
  }
  .home-container15 {
    gap: var(--dl-space-space-oneandhalfunits);
    margin-bottom: 0px;
  }
  .home-analyze {
    height: 100%;
    margin-bottom: 0px;
  }
  .home-text23 {
    text-align: center;
    font-family: Outfit;
  }
  .home-text24 {
    font-size: 20px;
    text-align: center;
  }
  .home-text25 {
    text-align: center;
    font-family: Outfit;
  }
  .home-text26 {
    font-size: 20px;
    text-align: center;
  }
  .home-how-it-works {
    padding: 0px;
    background-color: #EFF0F2;
  }
  .home-category1 {
    width: auto;
    height: 100%;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
  }
  .home-headng {
    width: auto;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text27 {
    font-size: 36px;
  }
  .home-text28 {
    font-size: 20px;
  }
  .home-banners {
    width: 100%;
    height: auto;
    margin-top: 0px;
    background-color: #EFF0F2;
  }
  .home-banner-advanced-analytics1 {
    height: auto;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-cards-image1 {
    width: 362px;
    height: 328px;
  }
  .home-text32 {
    font-size: 36px;
    font-family: Outfit;
    line-height: 48px;
  }
  .home-text34 {
    color: var(--dl-color-theme-primary1);
  }
  .home-text35 {
    text-align: center;
  }
  .home-banner1 {
    width: 100%;
    height: auto;
  }
  .home-text36 {
    font-size: 36px;
    line-height: 48px;
  }
  .home-text38 {
    color: var(--dl-color-theme-primary1);
  }
  .home-text40 {
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner-advanced-analytics2 {
    height: auto;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text41 {
    font-size: 36px;
    text-align: center;
    font-family: Outfit;
    line-height: 48px;
  }
  .home-text43 {
    color: var(--dl-color-theme-primary1);
  }
  .home-text44 {
    text-align: center;
  }
  .home-banner2 {
    width: 100%;
    height: auto;
    align-self: center;
  }
  .home-text45 {
    font-size: 36px;
    text-align: center;
    font-family: Outfit;
  }
  .home-text47 {
    color: var(--dl-color-theme-primary1);
  }
  .home-text48 {
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-banner-advanced-analytics3 {
    height: auto;
    align-self: center;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-right3 {
    flex: 1;
  }
  .home-text49 {
    font-size: 36px;
    text-align: center;
    font-family: Outfit;
    line-height: 48px;
  }
  .home-text52 {
    text-align: center;
  }
  .home-left3 {
    width: 100%;
    height: auto;
  }
  .home-text53 {
    font-size: 36px;
    text-align: center;
  }
  .home-text54 {
    width: auto;
    height: auto;
    text-align: center;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-cards-image6 {
    width: 350px;
    height: 470px;
  }
  .home-action-bar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text63 {
    font-size: 36px;
    align-self: center;
    text-align: center;
  }
  .home-text64 {
    font-size: 20px;
    align-self: flex-start;
  }
  .home-faq {
    padding: var(--dl-space-space-fourunits);
  }
  .home-text65 {
    align-self: center;
    text-align: center;
  }
  .home-text74 {
    text-align: left;
  }
  .home-footer {
    height: 1008px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: 0px;
  }
  .home-bottom {
    align-self: center;
  }
  .home-branding {
    align-self: center;
  }
  .home-top2 {
    height: auto;
    align-items: center;
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-right4 {
    width: auto;
    height: 100%;
    align-self: center;
    margin-top: var(--dl-space-space-threeunits);
    padding-bottom: 0px;
  }
  .home-category5 {
    width: 100%;
    height: 98px;
  }
  .home-text77 {
    align-self: center;
    text-align: center;
    font-family: Outfit;
  }
  .home-links4 {
    align-items: center;
  }
  .home-text81 {
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    text-align: center;
    font-family: Outfit;
    line-height: 1.15;
  }
  .home-text82 {
    font-family: Outfit;
  }
  .home-text83 {
    color: #c4c4c4;
    font-size: 14px;
    font-family: Outfit;
  }
  .home-text85 {
    font-family: Outfit;
    line-height: 0.45;
  }
  .home-category6 {
    width: 100%;
    height: auto;
    align-items: center;
  }
  .home-text86 {
    align-self: center;
    text-align: center;
  }
  .home-text87 {
    font-family: Outfit;
  }
  .home-text88 {
    font-family: Outfit;
  }
  .home-links5 {
    align-self: center;
  }
  .home-link15 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    text-align: center;
    font-family: Outfit;
  }
  .home-link16 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-link17 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-text90 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-text91 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-contact2 {
    width: 357px;
    height: auto;
    align-self: center;
    align-items: center;
  }
  .home-text92 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Outfit;
    font-weight: 700;
  }
  .home-text93 {
    color: #ffffff;
    font-size: 20px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: Outfit;
    font-weight: 700;
  }
  .home-link18 {
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    text-align: center;
    font-family: Outfit;
    text-decoration: underline;
  }
  .home-text95 {
    font-family: Outfit;
  }
  .home-text97 {
    font-family: Outfit;
  }
  .home-link19 {
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
    text-decoration: none;
  }
  .home-links6 {
    align-self: center;
  }
  .home-text99 {
    align-self: center;
    text-align: center;
  }
  .home-link20 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-link23 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-link24 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-link25 {
    fill: var(--dl-color-theme-secondary1);
    color: var(--dl-color-theme-secondary1);
    font-size: 20px;
    align-self: center;
    font-family: Outfit;
  }
  .home-text102 {
    fill: var(--dl-color-theme-accent1);
    color: var(--dl-color-theme-accent1);
    font-size: 12px;
    line-height: 25px;
  }
}
