.you-tube1-you-tube {
  width: 200px;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.you-tube1-pasted-image {
  width: var(--dl-size-size-large);
  height: 80px;
}
.you-tube1root-class-name {
  background-color: #FAFAFA;
}
.you-tube1root-class-name1 {
  background-color: #FFFFFF;
}
@media(max-width: 479px) {
  .you-tube1-you-tube {
    width: 120px;
    height: 60px;
    align-items: center;
    border-radius: 100px;
  }
  .you-tube1-pasted-image {
    width: 80px;
  }
}
